import { DqLoader } from "@decentriq/components";
import { memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminRoute, PrivateRoute } from "components";
import { useApiCore } from "contexts";
import { useOrganizationPreferences, useUserRole } from "hooks";
import { Main } from "layouts";
import {
  AccessTokensPage,
  AdminPortalPage,
  CreateDataRoomPage,
  DatalabsPage,
  DataPartnerPortalPage,
  DataRoomsPage,
  DatasetsPage,
  DraftDataRoomPage,
  FourOFourPage,
  HomePage,
  KeychainPage,
  LegacyAccessTokensPage,
  MediaDataRoomPage,
  PublishedDataRoomPage,
  PublisherPortalPage,
} from "pages";

const PagesWrapper = memo(() => {
  const {
    isOrganizationAdmin,
    isSuperAdmin,
    isSuperAdminReadOnly,
    organizationId: userOrganizationId,
    isLoading: isUserRolesLoading,
  } = useUserRole();
  const {
    hasDatalabFeatures,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
    isLoading: isOrganizationPreferencesLoading,
  } = useOrganizationPreferences();
  const { isMigrated } = useApiCore();
  if (isUserRolesLoading || isOrganizationPreferencesLoading) {
    return (
      <DqLoader
        size="sm"
        sx={{ height: "100%", justifyContent: "center", width: "100%" }}
      />
    );
  }
  return (
    <Main>
      <Routes>
        <Route element={<PrivateRoute component={HomePage} />} path="/" />
        <Route
          element={<PrivateRoute component={DatasetsPage} />}
          path="/datasets/*"
        />
        {/* TODO: Enable this route after MDCR v3 is finished */}
        {/* <Route
          element={<PrivateRoute component={CreateDataRoomPage} />}
          path="/datarooms/create/*"
        /> */}
        <Route
          element={<PrivateRoute component={DraftDataRoomPage} />}
          path="/datarooms/d/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={PublishedDataRoomPage} />}
          path="/datarooms/p/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={MediaDataRoomPage} />}
          path="/datarooms/mi/:dataRoomId/*"
        />
        <Route
          element={<PrivateRoute component={DataRoomsPage} />}
          path="/datarooms/:dataRoomId?"
        />
        <Route
          element={
            <PrivateRoute
              component={isMigrated ? AccessTokensPage : LegacyAccessTokensPage}
            />
          }
          path="/tokens"
        />
        {isMigrated ? (
          <Route
            element={<PrivateRoute component={LegacyAccessTokensPage} />}
            path="/legacy-tokens"
          />
        ) : undefined}
        {!isMigrated && (
          <Route
            element={<PrivateRoute component={KeychainPage} />}
            path="/keychain"
          />
        )}
        {hasDatalabFeatures &&
          !(hasPublisherFeatures || hasDataPartnerFeatures) && (
            <Route
              element={<PrivateRoute component={DatalabsPage} />}
              path="/datalabs/*"
            />
          )}
        {hasPublisherFeatures && (
          <Route
            element={<PrivateRoute component={PublisherPortalPage} />}
            path="/publisherportal/*"
          />
        )}
        {hasDataPartnerFeatures && (
          <Route
            element={<PrivateRoute component={DataPartnerPortalPage} />}
            path="/data-partner-portal/*"
          />
        )}
        <Route
          element={<AdminRoute component={AdminPortalPage} />}
          path="/admin/*"
        />
        {/* Redirecting to organizations page if user is super admin and organization page if organization admin */}
        <Route
          element={
            <Navigate
              replace={true}
              to={{
                pathname:
                  isSuperAdmin || isSuperAdminReadOnly
                    ? "/admin/organizations"
                    : isOrganizationAdmin
                      ? `/admin/organizations/${userOrganizationId}`
                      : "/",
              }}
            />
          }
          path="/admin"
        />
        <Route element={<Navigate replace={true} to="/" />} path="/signup" />
        <Route element={<FourOFourPage />} path="*" />
      </Routes>
    </Main>
  );
});

export default PagesWrapper;
