import { DqCard, DqCardContent } from "@decentriq/components";
import { faCheckCircle, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/joy";
import { memo, useMemo } from "react";
import { TimeAgoFormatted } from "components/base";
import { DataLabActions } from "features/dataLabs";
import {
  type DataLab,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";

interface DataLabsGridCardProps {
  dataLab: DataLab;
  onClick?: () => void;
}

const DataLabsGridCard = memo<DataLabsGridCardProps>(({ dataLab, onClick }) => {
  const {
    name,
    updatedAt,
    isValidated,
    matchingIdFormat,
    matchingIdHashingAlgorithm,
  } = dataLab;
  const theme = useTheme();
  const matchingIdValue = useMemo(() => {
    return matchingIdTypeAndHashingAlgorithmPresentation({
      matchingIdFormat,
      matchingIdHashingAlgorithm,
    });
  }, [matchingIdFormat, matchingIdHashingAlgorithm]);
  return (
    <DqCard onClick={onClick}>
      <DqCardContent alignItems="flex-start">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 1, width: "100%" }}
        >
          <Typography
            fontWeight="bold"
            startDecorator={
              <Tooltip
                component="span"
                title={isValidated ? "Validated" : "Not validated"}
              >
                <FontAwesomeIcon
                  color={
                    isValidated
                      ? theme.palette.success[500]
                      : theme.palette.warning[500]
                  }
                  fixedWidth={true}
                  icon={isValidated ? faCheckCircle : faWarning}
                />
              </Tooltip>
            }
          >
            {name}
          </Typography>
          {/* TODO Refactor this to use the "Actions" prop on DqCard along wiht a new Header component */}
          <Box onClick={(e) => e.stopPropagation()}>
            <DataLabActions id={dataLab.id} withDetails={false} />
          </Box>
        </Stack>
        <Stack spacing={0}>
          <Stack direction="row">
            <Typography level="body-sm">
              Matching ID: {matchingIdValue}
            </Typography>
          </Stack>
          <Typography level="body-sm">
            Last modified:{" "}
            {updatedAt ? (
              <TimeAgoFormatted
                date={updatedAt}
                style={{ whiteSpace: "nowrap" }}
              />
            ) : (
              "—"
            )}
          </Typography>
        </Stack>
      </DqCardContent>
    </DqCard>
  );
});

DataLabsGridCard.displayName = "DataLabsGridCard";

export default DataLabsGridCard;
