import { Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import {
  DataLabActions,
  DataLabStatusLabel,
  useDataLabContext,
  useDatalabsBaseUrl,
} from "features/dataLabs";

interface DataLabToolbarProps {
  id: string;
}

const DataLabToolbar: React.FC<DataLabToolbarProps> = ({ id }) => {
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  const datalabsBaseUrl = useDatalabsBaseUrl();
  const navigate = useNavigate();
  if (loading || !dataLab) return <></>;
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ minHeight: 68, p: 2 }}
    >
      <Typography
        endDecorator={<DataLabStatusLabel isValidated={dataLab.isValidated} />}
        level="h4"
      >
        {!dataLab?.name ? "Loading datalab name..." : dataLab.name}
      </Typography>
      <DataLabActions
        id={id}
        onDeleted={() => {
          navigate(datalabsBaseUrl);
        }}
      />
    </Stack>
  );
};
DataLabToolbar.displayName = "DataLabToolbar";

export default DataLabToolbar;
