// TODO: Split this component to extract grid, table & toolbar
import { useAuth0 } from "@auth0/auth0-react";
import { DqEmptyData, DqTable } from "@decentriq/components";
import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import {
  faArrowRight,
  faEyeSlash,
  faSearch,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { useDebounce } from "ahooks";
import { filter } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { Fragment, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataRoomDrawerVariant, StateIcon, TimeAgoFormatted } from "components";
import EntitiesSwitch, {
  SelectedView,
} from "components/base/EntitiesSwitch/EntitiesSwitch";
import EntitiesGrid from "components/base/EntititesGrid/EntititesGrid";
import { DataRoomCreateButton } from "features/dataRoomCreation";
import {
  dataRoomPathBuilder,
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";
import { type Organization, type User } from "types";
import DataRoomDrawer from "../DataRoomDrawer/DataRoomDrawer";
import DataRoomsGridCard from "./components/DataRoomsGridCard/DataRoomsGridCard";
import { DataRoomsListFilter, filterOptionsMap } from "./components";
import { type DataRoomListFilter, type DataRoomsListItem } from "./models";

const dataRoomsListColumns: MRT_ColumnDef<DataRoomsListItem>[] = [
  {
    Cell: ({ row }) => {
      const theme = useTheme();
      const {
        original: {
          __typename,
          name,
          userSettings = {
            isArchived: false,
            isFavorite: false,
          },
        },
        original,
      } = row;
      const isStopped = "isStopped" in original && original.isStopped;
      const isLocked =
        __typename === DataRoomTypeNames.PublishedDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;

      const statusColor = {
        DEACTIVATED: theme.palette.danger[500],
        DRAFT: theme.palette.info[500],
        PUBLISHED: theme.palette.primary[500],
        STOPPED: theme.palette.danger[500],
      };
      return (
        <Typography
          component="div"
          endDecorator={
            <Stack alignItems="center" spacing={0.5}>
              {userSettings?.isArchived && (
                <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
              )}
            </Stack>
          }
          fontWeight="500"
          level="inherit"
          noWrap={true}
          startDecorator={
            <StateIcon
              color={
                statusColor[
                  isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
                ]
              }
              status={
                isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
              }
            />
          }
          textColor="inherit"
        >
          <Typography component="span" noWrap={true}>
            {name}
          </Typography>
        </Typography>
      );
    },
    accessorKey: "name",
    header: "Name",
    id: "name",
    size: 270,
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return createdAt ? (
        <TimeAgoFormatted date={createdAt} style={{ whiteSpace: "nowrap" }} />
      ) : (
        "—"
      );
    },
    accessorKey: "createdAt",
    enableGlobalFilter: false,
    header: "Creation date",
    id: "createdAt",
  },
  {
    Cell: ({ cell }) => {
      const owner =
        cell.getValue<User & { organization: Organization }>() || {};
      return owner?.organization?.name || "-";
    },
    accessorKey: "owner",
    enableGlobalFilter: false,
    header: "Created by",
    id: "createdBy",
  },
  {
    Cell: ({ cell }) => {
      const __typename = cell.getValue() as DataRoomTypeNames;
      const dataRoomType = getDataRoomTypeFromTypename[__typename];
      const { title: dataRoomTypeTitle, icon: dataRoomTypeIcon } =
        dataRoomTypeCardPresentation[dataRoomType];
      return (
        <Stack alignItems="center" direction="row">
          <FontAwesomeIcon fixedWidth={true} icon={dataRoomTypeIcon} />
          <Typography level="body-sm">{dataRoomTypeTitle}</Typography>
        </Stack>
      );
    },
    accessorKey: "__typename",
    enableGlobalFilter: false,
    header: "Type",
    id: "__typename",
  },
];

const DataRoomsList: React.FC = () => {
  const navigate = useNavigate();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const {
    data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {},
    loading,
  } = useDataRoomsListQuery({ fetchPolicy: "network-only" });
  const { dataRoomId: selectedDataRoomId } = useParams();
  const [selectedView, setSelectedView] = useState<SelectedView>(
    SelectedView.Card
  );

  const handleSelectView = (value: SelectedView) => {
    if (value) {
      setSelectedView(value);
    }
  };
  const selectedDataRoom = dataRoomsList.find(
    ({ id }) => id === selectedDataRoomId
  );
  // Showing only dataroom types that are passed in the prop. If empty array - show all
  const [filterKey, setFilterKey] = useState<DataRoomListFilter["key"]>(
    filterOptionsMap.active.key
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const debouncedSearch = useDebounce(searchValue, { wait: 300 });

  const filteredDataRoomsList = useMemo(() => {
    const {
      equalsTo,
      field: filterField,
      value: filterValue,
    } = filterOptionsMap[filterKey];

    return filter(dataRoomsList, (dataRoom) => {
      // Search check
      const searchTerm = debouncedSearch.toLowerCase().trim();
      if (
        searchTerm &&
        !dataRoom.name.toLowerCase().trim().includes(searchTerm)
      ) {
        return false;
      }

      // Filter check
      if (filterKey === filterOptionsMap.all.key) {
        return true;
      }

      return equalsTo
        ? equalsTo(dataRoom, currentUserEmail)
        : dataRoom[filterField as keyof typeof dataRoom] === filterValue;
    });
  }, [dataRoomsList, filterKey, currentUserEmail, debouncedSearch]);
  // Empty state
  const isEmpty = !loading && !dataRoomsList.length;
  const isFilterEmpty = !loading && !filteredDataRoomsList.length;

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, py: 2 }}
      >
        <Stack direction="row">
          <EntitiesSwitch
            onChange={handleSelectView}
            selectedView={selectedView}
          />
          <DataRoomsListFilter
            filterKey={filterKey}
            setFilterKey={setFilterKey}
          />
          <Input
            endDecorator={
              searchValue ? (
                <IconButton onClick={() => setSearchValue("")}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              ) : null
            }
            onChange={onSearchChange}
            placeholder="Search data clean rooms"
            startDecorator={<FontAwesomeIcon icon={faSearch} />}
            sx={{ minWidth: "280px" }}
            value={searchValue}
          />
        </Stack>
        <DataRoomCreateButton />
      </Stack>
      <Divider sx={{ mb: 2, mx: 3 }} />
      {isFilterEmpty ? (
        <DqEmptyData title="No data clean rooms matched your criteria." />
      ) : (
        <Box
          sx={{
            alignItems: "stretch",
            backgroundColor: "common.white",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "stretch",
            overflow: "auto",
          }}
        >
          {selectedView === SelectedView.Table ? (
            <DqTable
              columns={dataRoomsListColumns}
              data={filteredDataRoomsList}
              displayColumnDefOptions={{
                // TODO: This is pretty common prop when dealing with row actions, consider moving it to DqTable itself or cover in the actions component that currently in the development by Volo & Annalisa
                "mrt-row-actions": {
                  enableResizing: false,
                  grow: false,
                  header: "Actions",
                  muiTableBodyCellProps: {
                    align: "left",
                    onClick: (event) => event.stopPropagation(),
                    sx: {
                      p: 0,
                    },
                  },
                  muiTableHeadCellProps: {
                    align: "left",
                  },
                  size: 140,
                },
              }}
              enableBatchRowSelection={false}
              enableGlobalFilter={true}
              enableMultiRowSelection={false}
              enableRowActions={true}
              enableRowSelection={true}
              enableSelectAll={false}
              enableSorting={true}
              enableTopToolbar={false}
              getRowId={(row) => row.id}
              initialState={{
                showGlobalFilter: true,
                sorting: [{ desc: true, id: "createdAt" }],
              }}
              localization={{
                noRecordsToDisplay: "No data clean rooms found",
              }}
              muiSearchTextFieldProps={{
                placeholder: "Search data clean rooms",
              }}
              muiTableBodyRowProps={({
                row: { getToggleSelectedHandler, original },
              }) => {
                return {
                  onClick: () => {
                    getToggleSelectedHandler();
                    navigate({ pathname: `/datarooms/${original.id}` });
                  },
                  sx: {
                    "& > .MuiTableCell-root:first-child": { pl: 2 },
                    "& > .MuiTableCell-root:last-child": { pr: 2 },
                    cursor: "pointer",
                  },
                };
              }}
              muiTableHeadRowProps={{
                sx: {
                  "& > .MuiTableCell-root:first-child": { pl: 2 },
                  "& > .MuiTableCell-root:last-child": {
                    "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                      right: "-1rem",
                    },
                    pr: 2,
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "100%",
                  overflow: "hidden",
                  width: "100%",
                },
              }}
              renderRowActions={({ row }) => {
                const { id, __typename } = row.original;
                const dataRoomLink = dataRoomPathBuilder(
                  id,
                  __typename as DataRoomTypeNames
                );
                return (
                  <Link to={dataRoomLink}>
                    <Button
                      endDecorator={
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faArrowRight}
                        />
                      }
                    >
                      Open
                    </Button>
                  </Link>
                );
              }}
              state={{
                columnVisibility: { "mrt-row-select": false },
                isLoading: loading,
                rowSelection: selectedDataRoomId
                  ? { [selectedDataRoomId]: true }
                  : {},
                showLoadingOverlay: false,
                showProgressBars: false,
                showSkeletons: loading,
              }}
            />
          ) : (
            <Box sx={{ mx: 3 }}>
              <EntitiesGrid
                data={filteredDataRoomsList}
                isEmpty={isEmpty}
                renderItem={(item) => {
                  return (
                    <DataRoomsGridCard dataRoom={item as DataRoomsListItem} />
                  );
                }}
              />
            </Box>
          )}
          <DataRoomDrawer
            __typename={selectedDataRoom?.__typename as DataRoomTypeNames}
            dataRoomId={selectedDataRoom?.id}
            onClose={() => navigate({ pathname: "/datarooms" })}
            open={Boolean(selectedDataRoom?.id)}
            variant={DataRoomDrawerVariant.DcrsList}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default DataRoomsList;
