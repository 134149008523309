import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/joy";
import { orderBy } from "lodash";
import { memo, useMemo } from "react";
import { RecentDataRoom } from "./components";

const RecentDataRoomsBlock = memo(() => {
  const { data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {} } =
    useDataRoomsListQuery({
      fetchPolicy: "network-only",
    });
  const recentDataRoomsList = useMemo(() => {
    return orderBy(dataRoomsList, "updatedAt", "desc").slice(0, 3);
  }, [dataRoomsList]);
  if (!recentDataRoomsList.length) return null;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography
        level="body-md"
        noWrap={true}
        sx={{ alignItems: "center", display: "flex", gap: 1 }}
      >
        <FontAwesomeIcon fixedWidth={true} icon={faClock} />
        <strong>Recents</strong>
      </Typography>
      <Grid columnSpacing={2} container={true}>
        {recentDataRoomsList.map((dataRoom) => (
          <Grid key={dataRoom.id} xs={4}>
            <RecentDataRoom
              dataRoom={dataRoom}
              key={dataRoom.id}
              variant="soft"
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});
RecentDataRoomsBlock.displayName = "RecentDataRoomsBlock";

export default RecentDataRoomsBlock;
