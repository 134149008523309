/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Option, Select } from "@mui/joy";
import { memo, type SetStateAction } from "react";
import { type DataRoomListFilter } from "../../models";

// An item will be filtered out only field's value === value defined in the object
export const filterOptionsMap: Record<string, DataRoomListFilter> = {
  active: {
    equalsTo: ({ userSettings, isStopped = false, deactivatedAt = null }) =>
      !(userSettings?.isArchived || isStopped || deactivatedAt),
    field: null,
    key: "active",
    label: "Active",
  },
  sharedWithMe: {
    equalsTo: (dataRoom, currentUserEmail) =>
      dataRoom?.owner?.email !== currentUserEmail,
    field: "owner.email",
    key: "sharedWithMe",
    label: "Shared with me",
  },
  favourites: {
    field: "userSettings.isFavorite",
    key: "favourites",
    label: "Favourites",
    value: true,
  },
  hidden: {
    field: "userSettings.isArchived",
    key: "hidden",
    label: "Hidden",
    value: true,
  },
  stopped: {
    field: "isStopped",
    key: "stopped",
    label: "Stopped",
    value: true,
  },
  all: {
    field: null,
    key: "all",
    label: "All",
    value: null,
  },
};

const filterOptions = Object.values(filterOptionsMap);

interface DataRoomsListFilterProps {
  filterKey: DataRoomListFilter["key"];
  setFilterKey: (value: SetStateAction<DataRoomListFilter["key"]>) => void;
}

const DataRoomsListFilter = memo<DataRoomsListFilterProps>(
  ({ filterKey, setFilterKey }) => {
    return (
      <Select
        defaultValue={filterOptionsMap.active.key}
        onChange={(event, value) => {
          setFilterKey(value as DataRoomListFilter["key"]);
        }}
        value={filterKey}
      >
        {filterOptions.map(({ label, key }: DataRoomListFilter) => (
          <Option key={key} value={key}>
            {label}
          </Option>
        ))}
      </Select>
    );
  }
);

DataRoomsListFilter.displayName = "DataRoomsListFilter";

export default DataRoomsListFilter;
