// TODO: Split this component to extract grid, table & toolbar
import { DqEmptyData, DqLoader, DqTable } from "@decentriq/components";
import { type Dataset } from "@decentriq/graphql/dist/types";
import {
  faArrowRight,
  faCheck,
  faSearch,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { useDebounce } from "ahooks";
import { filter, isEmpty } from "lodash";
import {
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField as DatalabsSearchField,
  type MRT_RowSelectionState,
} from "material-react-table";
import { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TimeAgoFormatted } from "components";
import EntitiesSwitch, {
  SelectedView,
} from "components/base/EntitiesSwitch/EntitiesSwitch";
import EntitiesGrid from "components/base/EntititesGrid/EntititesGrid";
import {
  DataLabCreateButton,
  DataLabDrawer,
  DataLabStatusLabel,
  useDatalabsBaseUrl,
} from "features/dataLabs";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { type DataLab } from "features/dataLabs/models";
import { useDataLabsContext } from "../../contexts";
import DataLabsGridCard from "../DataLabsGridCard/DataLabsGridCard";

const dataLabsColumnDef: MRT_ColumnDef<DataLab>[] = [
  {
    Cell: ({ cell }) => {
      return (
        <Typography
          component="span"
          fontWeight="500"
          level="inherit"
          noWrap={true}
          textColor="inherit"
        >
          {cell.getValue<string>()}
        </Typography>
      );
    },
    accessorKey: "name",
    header: "Name",
    id: "name",
  },
  {
    Cell: ({ cell }) => {
      const isValidated = cell.getValue<boolean>();
      return <DataLabStatusLabel isValidated={isValidated} />;
    },
    accessorKey: "isValidated",
    header: "Status",
    id: "status",
    size: 120,
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return createdAt ? <TimeAgoFormatted date={createdAt} /> : "—";
    },
    accessorKey: "updatedAt",
    header: "Last modified",
    id: "updatedAt",
    size: 120,
  },
  {
    Cell: ({ row }) => {
      const { matchingIdFormat, matchingIdHashingAlgorithm } = row.original;
      return matchingIdTypeAndHashingAlgorithmPresentation({
        matchingIdFormat,
        matchingIdHashingAlgorithm,
      });
    },
    header: "Matching ID",
    id: "matchingId",
    size: 120,
  },
  {
    Cell: ({ cell }) => {
      const matchingDataset = cell.getValue<Dataset | null>();
      return !isEmpty(matchingDataset) ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "usersDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Matching",
    id: "matchingDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell }) => {
      const segmentsDataset = cell.getValue<Dataset | null>();
      return segmentsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "segmentsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Segments",
    id: "segmentsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell }) => {
      const demographicsDataset = cell.getValue<Dataset | null>();
      return demographicsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "demographicsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Demographics",
    id: "demographicsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ cell, row }) => {
      const embeddingsDataset = cell.getValue<Dataset | null>();
      return embeddingsDataset ? (
        <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
      ) : null;
    },
    accessorKey: "embeddingsDataset",
    enableResizing: false,
    enableSorting: false,
    header: "Embeddings",
    id: "embeddingsDataset",
    muiTableBodyCellProps: { align: "center" },
    muiTableHeadCellProps: { align: "center" },
    size: 80,
  },
  {
    Cell: ({ row }) => {
      const navigate = useNavigate();
      const datalabsBaseUrl = useDatalabsBaseUrl();
      return (
        <Button
          endDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
          }
          onClick={() => navigate(`${datalabsBaseUrl}/${row.original.id}`)}
        >
          Go to datalab
        </Button>
      );
    },
    enableResizing: false,
    grow: false,
    header: "Actions",
    id: "actions",
    muiTableBodyCellProps: { sx: { p: 0 } },
    size: 160,
  },
];

const DataLabsList: React.FC = () => {
  const {
    dataLabs: { data: dataLabs = [], loading },
  } = useDataLabsContext();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedDataLab] = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => dataLabs.find(({ id }) => id === key));

  const [selectedView, setSelectedView] = useState<SelectedView>(
    SelectedView.Card
  );
  const handleSelectView = (value: SelectedView) => {
    setSelectedView(value);
  };
  const navigate = useNavigate();
  const datalabsBaseUrl = useDatalabsBaseUrl();

  const [searchValue, setSearchValue] = useState<string>("");
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const debouncedSearch = useDebounce(searchValue, { wait: 300 });

  const filteredDataLabs = useMemo(() => {
    return filter(dataLabs, (dataLab) => {
      return dataLab.name
        .trim()
        .toLowerCase()
        .includes(debouncedSearch.trim().toLowerCase());
    });
  }, [dataLabs, debouncedSearch]);
  // Empty state
  const isEmpty = !loading && !dataLabs.length;
  const isFilterEmpty = !loading && !filteredDataLabs.length;

  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="absolute"
        top="0"
        width="100%"
      >
        <DqLoader />
      </Box>
    );
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, py: 2 }}
      >
        <Stack direction="row">
          <EntitiesSwitch
            onChange={handleSelectView}
            selectedView={selectedView}
          />
          <Input
            endDecorator={
              searchValue ? (
                <IconButton onClick={() => setSearchValue("")}>
                  <FontAwesomeIcon icon={faXmark} />
                </IconButton>
              ) : null
            }
            onChange={onSearchChange}
            placeholder="Search data labs"
            startDecorator={<FontAwesomeIcon icon={faSearch} />}
            sx={{ minWidth: "280px" }}
            value={searchValue}
          />
        </Stack>
        <DataLabCreateButton />
      </Stack>
      <Divider sx={{ mb: 2, mx: 3 }} />
      {isFilterEmpty ? (
        <DqEmptyData title="No data labs matched your criteria." />
      ) : (
        <Box
          sx={{
            alignItems: "stretch",
            backgroundColor: "common.white",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "stretch",
            overflow: "auto",
          }}
        >
          {selectedView === SelectedView.Table ? (
            <DqTable
              autoSelectFirstRow={false}
              columns={dataLabsColumnDef}
              data={filteredDataLabs}
              enableBatchRowSelection={false}
              enableGlobalFilter={true}
              enableMultiRowSelection={false}
              enableRowActions={false}
              enableRowSelection={true}
              enableSelectAll={false}
              enableSorting={true}
              enableTopToolbar={false}
              getRowId={(row) => row.id}
              initialState={{
                showGlobalFilter: true,
                sorting: [{ desc: true, id: "updatedAt" }],
              }}
              localization={{
                noRecordsToDisplay: "You haven't created any datalabs yet",
              }}
              muiSearchTextFieldProps={{
                placeholder: "Search datalabs",
              }}
              muiTableBodyRowProps={({
                row: { getToggleSelectedHandler, getIsSelected },
              }) => {
                return {
                  onClick: getIsSelected()
                    ? undefined
                    : getToggleSelectedHandler(),
                  sx: {
                    "& > .MuiTableCell-root:first-child": { pl: 2 },
                    "& > .MuiTableCell-root:last-child": { pr: 2 },
                    cursor: "pointer",
                  },
                };
              }}
              muiTableHeadRowProps={{
                sx: {
                  "& > .MuiTableCell-root:first-child": { pl: 2 },
                  "& > .MuiTableCell-root:last-child": {
                    "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                      right: "-1rem",
                    },
                    pr: 2,
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "100%",
                  overflow: "hidden",
                  width: "100%",
                },
              }}
              onRowSelectionChange={setRowSelection}
              // TODO Incorporate with the new grid switch and use this instead of the current one
              renderTopToolbar={({ table }) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{ px: 1 }}
                >
                  <DatalabsSearchField table={table} />
                  <DataLabCreateButton />
                </Stack>
              )}
              renderTopToolbarCustomActions={() => null} // NOTE: Hack to prevent MRT from switching the toolbar to `position: absolute;` because of `stackAlertBanner`
              state={{
                columnVisibility: { "mrt-row-select": false },
                rowSelection,
              }}
            />
          ) : (
            <Box sx={{ mx: 3 }}>
              <EntitiesGrid
                data={filteredDataLabs}
                isEmpty={isEmpty}
                renderItem={(item) => {
                  return (
                    <DataLabsGridCard
                      dataLab={item as DataLab}
                      onClick={() => navigate(`${datalabsBaseUrl}/${item.id}`)}
                    />
                  );
                }}
              />
            </Box>
          )}
          <DataLabDrawer
            onClose={() => setRowSelection({})}
            open={!loading && !!selectedDataLab?.id}
            selectedDataLabId={selectedDataLab?.id}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default DataLabsList;
