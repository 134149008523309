import {
  faTable,
  faTableRows,
  type IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, iconButtonClasses, ToggleButtonGroup } from "@mui/joy";
import { memo } from "react";

/**
 * View options for entity display
 */
export enum SelectedView {
  Card = "card",
  Table = "table",
}

interface EntitiesSwitchProps {
  /**
   * Currently selected view
   */
  selectedView: SelectedView;
  /**
   * Callback when view selection changes
   */
  onChange: (value: SelectedView) => void;
}

const selectedViewIconsPresentation: Record<SelectedView, IconDefinition> = {
  [SelectedView.Table]: faTableRows,
  [SelectedView.Card]: faTable,
};

const selectedViewOptions = [SelectedView.Table, SelectedView.Card].map(
  (value) => ({
    icon: selectedViewIconsPresentation[value],
    value,
  })
);

/**
 * Toggle switch between card and table views for entity displays
 */
const EntitiesSwitch = memo<EntitiesSwitchProps>(
  ({ selectedView = SelectedView.Table, onChange }) => {
    return (
      <ToggleButtonGroup
        onChange={(_, value) => onChange(value!)}
        size="sm"
        value={selectedView}
        variant="outlined"
      >
        {selectedViewOptions.map(({ value, icon }) => (
          <IconButton
            color={value === selectedView ? "primary" : "neutral"}
            key={value}
            sx={(theme) => ({
              [`&.${iconButtonClasses.variantSoft}`]: {
                backgroundColor: theme.palette.primary.softBg,
                border: 1,
                borderColor: theme.palette.neutral.outlinedBorder,
              },
              px: 2,
              py: 1,
            })}
            value={value}
            variant={value === selectedView ? "soft" : "outlined"}
          >
            <FontAwesomeIcon icon={icon} />
          </IconButton>
        ))}
      </ToggleButtonGroup>
    );
  }
);

EntitiesSwitch.displayName = "EntitiesSwitch";
export default EntitiesSwitch;
