import { DqCard, DqCardContent } from "@decentriq/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/joy";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { StateIcon, TimeAgoFormatted } from "components/base";
import { DataRoomActions, DataRoomActionTypes } from "features";
import {
  dataRoomPathBuilder,
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";
import { type DataRoomsListItem } from "../../models";

interface DataRoomGridCardProps {
  dataRoom: DataRoomsListItem;
}

const DataRoomGridCard = memo<DataRoomGridCardProps>(({ dataRoom }) => {
  const navigate = useNavigate();
  const { name, id, __typename, createdAt, owner } = dataRoom;
  const isLocked =
    __typename === DataRoomTypeNames.PublishedDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
    __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;
  const isStopped = "isStopped" in dataRoom && dataRoom.isStopped;

  const dataRoomType = getDataRoomTypeFromTypename[__typename!];
  const { title: dataRoomTypeTitle, icon: dataRoomTypeIcon } =
    dataRoomTypeCardPresentation[dataRoomType];
  const dataRoomLink = dataRoomPathBuilder(id, __typename as DataRoomTypeNames);

  const dataRoomGridViewActions = ({
    buttons,
    menuLists,
  }: {
    buttons: any[];
    menuLists: any[];
  }) => {
    return {
      buttons: [
        ...buttons.filter(
          ({ type }: any) =>
            ![
              DataRoomActionTypes.Publish,
              DataRoomActionTypes.ToggleFavorite,
            ].includes(type)
        ),
      ],
      menuLists,
    };
  };

  const theme = useTheme();

  const statusColor = {
    DEACTIVATED: theme.palette.danger[500],
    DRAFT: theme.palette.info[500],
    PUBLISHED: theme.palette.primary[500],
    STOPPED: theme.palette.danger[500],
  };

  return (
    <DqCard onClick={() => navigate(dataRoomLink, { replace: false })}>
      <DqCardContent alignItems="flex-start">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography
            fontWeight="bold"
            startDecorator={
              <StateIcon
                color={
                  statusColor[
                    isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
                  ]
                }
                status={
                  isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
                }
              />
            }
          >
            {name}
          </Typography>
          {__typename && (
            //   TODO Refactor this to use the "Actions" prop on DqCard along with a new Header component
            <Box onClick={(e) => e.stopPropagation()}>
              <DataRoomActions
                __typename={__typename as DataRoomTypeNames}
                actions={dataRoomGridViewActions}
                id={id}
              />
            </Box>
          )}
        </Stack>
        <Stack spacing={0}>
          <Typography level="body-sm">
            Creation date:{" "}
            {createdAt ? (
              <TimeAgoFormatted
                date={createdAt}
                style={{ whiteSpace: "nowrap" }}
              />
            ) : (
              "—"
            )}
          </Typography>
          <Typography level="body-sm">
            Created by: {owner?.organization?.name}
          </Typography>
          <Stack alignItems="center" direction="row">
            <Typography level="body-sm">Type: </Typography>
            <Typography
              level="body-sm"
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={dataRoomTypeIcon} />
              }
            >
              {dataRoomTypeTitle}
            </Typography>
          </Stack>
        </Stack>
      </DqCardContent>
    </DqCard>
  );
});

DataRoomGridCard.displayName = "DataRoomGridCard";

export default DataRoomGridCard;
