import { DqCard } from "@decentriq/components";
import { type DqCardColor } from "@decentriq/components/dist/components/DqCard/models";
import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/joy";
import { memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import { StateIcon } from "components";
import { useTimeFormatter } from "hooks";
import {
  dataRoomPathBuilder,
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";

type RecentDataRoomVariant = "outlined" | "soft";

interface RecentDataRoomProps {
  dataRoom: DataRoomsListQuery["dataRooms"]["nodes"][number];
  variant: RecentDataRoomVariant;
}

const RecentDataRoom = memo<RecentDataRoomProps>(
  ({ dataRoom, variant = "soft" }) => {
    // isStopped doesn't exist on DraftDataRoom
    const { __typename, id, isStopped, name, updatedAt } = dataRoom;
    const dataRoomType = getDataRoomTypeFromTypename[__typename!];
    const { title: dataRoomTypeTitle } =
      dataRoomTypeCardPresentation[dataRoomType];
    const timeFormatter = useTimeFormatter();
    const theme = useTheme();
    const navigate = useNavigate();
    const dataRoomLink = dataRoomPathBuilder(
      id,
      __typename as DataRoomTypeNames
    );
    const isLocked =
      __typename === DataRoomTypeNames.PublishedDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaInsightsDcr ||
      __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom;

    const mapStatusToColor = useMemo(() => {
      if (isLocked) {
        return isStopped
          ? theme.palette.neutral[500]
          : theme.palette.primary[500];
      }
      return theme.palette.info[500];
    }, [isLocked, theme.palette, isStopped]);

    const mapCardColor: DqCardColor = useMemo(() => {
      if (isLocked) {
        return isStopped ? "neutral" : "primary";
      }
      return "info";
    }, [isLocked, isStopped]);

    return (
      <DqCard
        color={variant === "soft" ? mapCardColor : "neutral"}
        onClick={() => navigate(dataRoomLink)}
        variant={variant === "soft" ? "soft" : "outlined"}
      >
        <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
          <StateIcon
            color={variant === "soft" ? mapStatusToColor : "neutral"}
            status={isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"}
          />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flex: 1,
              gap: 1,
              overflow: "hidden",
            }}
          >
            <Typography fontWeight="bold" level="body-md" noWrap={true}>
              {name}
            </Typography>
          </Box>
          <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
        </Box>
        <Stack direction="row" spacing={1}>
          <Typography level="body-sm">{dataRoomTypeTitle}</Typography>
          <Typography level="body-sm"> · </Typography>
          <Typography level="body-sm">
            Last activity{" "}
            <TimeAgo
              date={updatedAt}
              formatter={timeFormatter}
              style={{ whiteSpace: "nowrap" }}
            />
          </Typography>
        </Stack>
      </DqCard>
    );
  }
);

RecentDataRoom.displayName = "RecentDataRoom";

export default RecentDataRoom;
