import { DqEmptyData } from "@decentriq/components";
import { Grid } from "@mui/joy";
import { memo } from "react";

interface EntitiesGridProps<T> {
  data: T[];
  isEmpty: boolean;
  renderItem: (item: T) => React.ReactNode;
}

const EntitiesGrid = memo(
  <T extends { id: string }>({
    data,
    isEmpty,
    renderItem,
  }: EntitiesGridProps<T>) => {
    if (isEmpty) {
      return <DqEmptyData title="No items found" />;
    }

    return (
      <Grid container={true} spacing={2}>
        {data.map((item) => (
          <Grid key={item.id} md={3} sx={{ cursor: "pointer" }}>
            {renderItem(item)}
          </Grid>
        ))}
      </Grid>
    );
  }
);

EntitiesGrid.displayName = "EntitiesGrid";

export default EntitiesGrid;
