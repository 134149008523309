import { Box, Divider, Stack, Typography } from "@mui/joy";
import { Fragment } from "react";
import { DataRoomsList, RecentDataRoomsBlock } from "components";

const DataRoomsPage = () => {
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, mx: 1, my: 0.5 }}
      >
        <Typography level="h4">Data clean rooms</Typography>
      </Stack>
      <Divider sx={{ mb: 2 }} />
      <Stack spacing={0} sx={{ flex: 1, overflow: "hidden" }}>
        <Box sx={{ pb: 2, px: 6.5 }}>
          <RecentDataRoomsBlock />
        </Box>
        <DataRoomsList />
      </Stack>
    </Fragment>
  );
};

export default DataRoomsPage;
